<template>
  <v-container fluid class="pb-0">
    <v-row>
      <v-col md="2">
        <v-text-field
          v-model="filter_search"
          v-on:click:clear="clearFilter('search')"
          clearable
          dense
          filled
          flat
          label="Search"
          hide-details
          solo
          :loading="pageLoading"
          :disabled="pageLoading"
          color="cyan"
          @keydown.enter="getVisits"
          @keydown.tab="getVisits"
        ></v-text-field>
      </v-col>
      <v-col md="3">
        <v-menu
          v-model="datePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              filled
              flat
              label="Visit Date Range"
              hide-details
              solo
              :loading="pageLoading"
              :disabled="pageLoading"
              clearable
              prepend-inner-icon="mdi-calendar"
              v-on:click:clear="clearFilter('dates')"
              readonly
              v-bind="attrs"
              v-on="on"
              :value="formattedDate"
              color="cyan"
            ></v-text-field>
          </template>
          <v-date-picker
            color="cyan"
            range
            :disabled="pageLoading"
            v-on:change="getVisits"
            v-model="dates"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col md="2">
        <v-autocomplete
          hide-details
          v-model="filter_status"
          :items="statusList"
          dense
          flat
          filled
          label="Visit Status"
          item-color="cyan"
          color="cyan"
          solo
          :disabled="pageLoading"
          :loading="pageLoading"
          v-on:change="getVisits"
          item-value="status"
          item-text="text"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title
                v-html="'No Status(s) Found.'"
              ></v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item }">
            <v-list-item-content class="py-0">
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
              >
                <template v-if="item.value == 'all'"
                  >{{ item.text }} ({{ item.all_ticket_visits }})</template
                >
                <template v-else
                  >{{ item.text }} ({{
                    item.ticket_visit_status_count
                  }})</template
                >
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
                >{{ item.text }}</v-list-item-title
              >
              <v-list-item-title
                class="font-weight-500 text--secondary font-size-16"
                >{{ item.description }}</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-action class="align-self-center">
              <v-list-item-subtitle
                class="text-lowercase font-weight-500 font-size-14"
              >
                <template v-if="item.value == 'all'">{{
                  item.all_ticket_visits
                }}</template>
                <template v-else>{{
                  item.ticket_visit_status_count
                }}</template></v-list-item-subtitle
              >
            </v-list-item-action>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col md="2">
        <v-autocomplete
          hide-details
          v-model="filter_customer"
          clearable
          :items="customerList"
          dense
          flat
          filled
          label="Customer"
          item-color="cyan"
          color="cyan"
          solo
          :disabled="pageLoading || customerLoading"
          :loading="customerLoading || pageLoading"
          item-text="display_name"
          item-value="id"
          v-on:change="getPropertyList"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title
                v-html="'No Customer(s) Found.'"
              ></v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-avatar>
              <v-img
                :lazy-src="$defaultProfileImage"
                :src="$assetAPIURL(item.profile_logo)"
                aspect-ratio="1"
                class="margin-auto"
                transition="fade-transition"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
                >{{ item.display_name }}</v-list-item-title
              >
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
                >{{ item.company_name }}</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-action
              v-if="item.default_person"
              class="align-self-center"
            >
              <v-list-item-subtitle
                class="text-lowercase pb-2 font-weight-500 font-size-14"
                >{{ item.default_person.primary_email }}</v-list-item-subtitle
              >
              <v-list-item-subtitle
                class="text-lowercase font-weight-500 font-size-14"
                >{{ item.default_person.primary_phone }}</v-list-item-subtitle
              >
            </v-list-item-action>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col md="2">
        <v-autocomplete
          hide-details
          clearable
          v-model="filter_property"
          :items="propertyList"
          dense
          flat
          filled
          label="Property"
          item-color="cyan"
          color="cyan"
          solo
          :disabled="pageLoading || propertyLoading"
          :loading="propertyLoading || pageLoading"
          item-text="barcode"
          item-value="id"
          v-on:change="getVisits"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title
                v-html="'No Property(s) Found.'"
              ></v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-icon class="mr-3">
              <v-icon large>mdi-home-map-marker</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
                >{{ item.barcode }} ({{ getFormattedProperty(item) }})
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col md="1">
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              tile
              :disabled="pageLoading"
              v-on:click="getVisits"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <span>Search</span>
        </v-tooltip>
      </v-col>
      <v-col md="12">
        <v-simple-table
          class="inner-simple-table custom-border-top"
          fixed-header
        >
          <template v-slot:default>
            <tbody>
              <template v-if="lodash.isEmpty(rowData) === false">
                <template v-for="(row, index) in rowData">
                  <tr
                    v-if="row.header"
                    :key="index"
                    :class="{ 'custom-border-top': index > 0 }"
                  >
                    <td
                      colspan="6"
                      class="font-size-16 text-uppercase py-2 font-weight-700 custom-border-bottom"
                      :class="[row.header_text]"
                      :style="{ color: row.header_color }"
                    >
                      {{ row.header_text }}
                    </td>
                  </tr>
                  <template v-else>
                    <v-hover v-slot="{ hover }" :key="index">
                      <tr
                        link
                        :key="'visit-row' + index"
                        :class="{
                          'visit-row-hover': hover,
                          'custom-border-bottom': rowData[index + 1],
                          'row-loading': isRowLoading[index],
                        }"
                        v-on:click="
                          $router.push(
                            getDefaultRoute('visit.detail', {
                              params: { id: row.visit_id },
                            })
                          )
                        "
                      >
                        <td
                          width="150"
                          :class="[row.header_text]"
                          class="font-size-14 font-weight-500"
                          :style="{ color: row.header_color }"
                        >
                          <template v-if="row.visit_barcode">
                            <div class="cursor-pointer">
                              <Barcode :barcode="row.visit_barcode"></Barcode>
                            </div>
                            <div
                              class="cursor-pointer"
                              v-on:click.stop.prevent="
                                $router.push({
                                  name: 'engineer.job.detail',
                                  params: { id: row.ticket_id },
                                })
                              "
                            >
                              <Barcode :barcode="row.ticket_barcode"></Barcode>
                            </div>
                          </template>
                          <template v-else> - </template>
                        </td>
                        <td
                          width="400"
                          :class="[row.header_text]"
                          class="font-size-14 font-weight-500"
                        >
                          <div class="job-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Title: </b>{{ row.visit_title }}
                            </p>
                            <p
                              class="m-0 custom-nowrap-ellipsis-two-line"
                              v-if="row.property_address"
                            >
                              <b>Property: </b> {{ row.property_address }}
                            </p>
                          </div>
                        </td>
                        <td
                          width="300"
                          :class="[row.header_text]"
                          class="font-size-14 font-weight-500"
                        >
                          <div class="job-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Name: </b>
                              {{
                                row.ticket_attention
                                  ? row.ticket_attention
                                  : row.customer_display_name
                              }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Company: </b> {{ row.customer_company_name }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Email: </b>
                              {{ row.customer_primary_email }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Phone: </b>
                              {{ row.customer_primary_phone }}
                            </p>
                          </div>
                        </td>
                        <td
                          width="200"
                          :class="[row.header_text]"
                          class="font-size-14 font-weight-500"
                          :style="{ color: row.header_color }"
                        >
                          <p
                            class="m-0 max-content-width custom-nowrap-ellipsis"
                          >
                            <span class="d-block">
                              {{ formatDateTime(row.visit_started_at) }} -
                              {{ formatTime(row.visit_finished_at) }}
                            </span>
                            <span class="d-block">
                              <v-chip
                                class="mt-2 mb-2 font-weight-600 cursor-pointer text-lowercase"
                                color="chip-custom-blue"
                                outlined
                                label
                              >
                                <template v-if="row.visit_status === 4">
                                  completed in {{ row.visit_duration_human }}
                                </template>
                                <template v-else-if="row.visit_status === 2">
                                  in-progress
                                </template>
                                <template v-else>
                                  <template v-if="row.visit_overdue === 1"
                                    >delayed by
                                    {{
                                      calculateTotalDuration(
                                        row.visit_started_at
                                      )
                                    }}</template
                                  >
                                  <template v-else-if="row.visit_today === 1"
                                    >ends in
                                    {{
                                      calculateTotalDuration(
                                        row.visit_finished_at
                                      )
                                    }}</template
                                  >
                                  <template v-else
                                    >starts in
                                    {{
                                      calculateTotalDuration(
                                        row.visit_started_at
                                      )
                                    }}</template
                                  >
                                </template>
                              </v-chip>
                            </span>
                          </p>
                        </td>
                        <td class="font-size-14 font-weight-500">
                          <CustomStatus
                            :status.sync="row.visit_status"
                            endpoint="visit/status"
                          ></CustomStatus>
                        </td>
                        <td class="font-size-14 font-weight-500">
                          <p
                            class="m-0 max-content-width custom-nowrap-ellipsis"
                            :class="{
                              'text--secondary': lodash.isEmpty(
                                row.visit_engineers
                              ),
                            }"
                          >
                            {{ engineerDisplayText(row.visit_engineers) }}
                          </p>
                        </td>
                      </tr>
                    </v-hover>
                  </template>
                </template>
              </template>
              <template v-else-if="pageLoading">
                <template v-for="(row, index) in 3">
                  <tr :key="'ref-0-' + index">
                    <td colspan="6">
                      <v-skeleton-loader
                        type="text"
                        class="custom-skeleton table-rows-text width-350px"
                      ></v-skeleton-loader>
                    </td>
                  </tr>
                  <tr :key="'ref-1-' + index">
                    <td width="150">
                      <v-skeleton-loader
                        type="text"
                        class="custom-skeleton table-rows-text"
                      ></v-skeleton-loader>
                    </td>
                    <td width="400">
                      <v-skeleton-loader type="paragraph"></v-skeleton-loader>
                    </td>
                    <td width="300">
                      <v-skeleton-loader type="paragraph"></v-skeleton-loader>
                    </td>
                    <td width="200" class="py-4">
                      <v-skeleton-loader type="text"></v-skeleton-loader>
                      <v-skeleton-loader
                        type="text"
                        class="custom-skeleton table-rows-text"
                      ></v-skeleton-loader>
                    </td>
                    <td>
                      <v-skeleton-loader type="text"></v-skeleton-loader>
                    </td>
                    <td>
                      <v-skeleton-loader type="text"></v-skeleton-loader>
                    </td>
                  </tr>
                </template>
              </template>
              <tr v-else>
                <td colspan="5" class="py-4">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no visit at the moment.
                  </p>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col md="12">
        <ListingFooter
          :dataLoading.sync="pageLoading"
          :currentPage.sync="currentPage"
          :totalPages.sync="totalPages"
          :showingFrom.sync="showingFrom"
          :showingTo.sync="showingTo"
          :totalRows.sync="totalRows"
        ></ListingFooter>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import { GET, QUERY } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import { getData, saveData } from "@/core/services/local.service";
import Barcode from "@/view/pages/partials/Barcode.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ListingFooter from "@/view/pages/partials/Listing-Footer";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "visit-listing-template",
  mixins: [CommonMixin],
  props: {
    visitType: {
      type: String,
      required: true,
      default: "all",
    },
  },
  components: {
    Barcode,
    CustomStatus,
    ListingFooter,
  },
  data() {
    return {
      totalRows: 0,
      rowsOffset: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 15,
      pageLoading: true,
      customerLoading: true,
      propertyLoading: true,
      isRowLoading: {},
      dates: [],
      statusList: [],
      customerList: [],
      propertyList: [],
      rowData: [],
      filter_search: null,
      filter_customer: null,
      filter_status: null,
      filter_property: null,
      timeout: null,
      timeoutLimit: 500,
    };
  },
  mounted() {
    const _this = this;
    _this.getVisits();
    _this.getCustomerList();
    _this
      .getStatusList()
      .then((status) => {
        _this.statusList = status;
      })
      .catch((error) => {
        _this.logError(error);
      });
  },
  methods: {
    calculateTotalDuration(visit_date) {
      if (moment(visit_date).isValid()) {
        let a = moment(visit_date);
        let b = moment();

        let totalDuration = moment.duration(a.diff(b));
        if (b.isAfter(a)) {
          totalDuration = moment.duration(b.diff(a));
        }

        let result = [];

        let years = totalDuration.get("years");
        if (years > 0) {
          if (years == 1) {
            result.push(years + " Year");
          } else {
            result.push(years + " Years");
          }
        }

        let months = totalDuration.get("months");
        if (months > 0) {
          if (months == 1) {
            result.push(months + " Month");
          } else {
            result.push(months + " Months");
          }
        }

        let days = totalDuration.get("days");
        if (days > 0) {
          if (days == 1) {
            result.push(days + " Day");
          } else {
            result.push(days + " Days");
          }
        }

        let hours = totalDuration.get("hours");
        if (hours > 0) {
          if (hours == 1) {
            result.push(hours + " Hour");
          } else {
            result.push(hours + " Hours");
          }
        }

        let minutes = totalDuration.get("minutes");
        if (minutes > 0) {
          if (minutes == 1) {
            result.push(minutes + " Minute");
          } else {
            result.push(minutes + " Minutes");
          }
        }

        return result.join(" ");
      }
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter_search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getVisits();
    },
    getVisits() {
      const _this = this;
      let filter = {
        daterange: _this.dates,
        search: _this.lodash.toString(_this.filter_search),
        customer: _this.lodash.toSafeInteger(_this.filter_customer) || null,
        status: _this.lodash.toSafeInteger(_this.filter_status) || null,
        property: _this.lodash.toSafeInteger(_this.filter_property) || null,
        type: _this.visitType,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      }

      _this.rowData = [];
      _this.pageLoading = true;
      let current_page = _this.currentPage;
      let per_page = _this.perPage;
      _this
        .getVisitList({ filter, current_page, per_page })
        .then((data) => {
          _this.rowData = data.rows;
          _this.totalPages = Math.ceil(data.total_rows / data.per_page);
          _this.currentPage = data.current_page;
          _this.totalRows = data.total_rows;
          _this.rowsOffset = data.offset;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    engineerDisplayText(param) {
      const _this = this;
      let display_name = new Array();
      if (
        _this.lodash.isEmpty(param) === false &&
        _this.lodash.isArray(param)
      ) {
        for (let i = 0; i < param.length; i++) {
          display_name.push(param[i].display_name);
        }
      }
      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned yet.";
      } else {
        return "Assigned to " + display_name.join(", ");
      }
    },
    getVisitList(data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "visit",
              data,
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDefaultPerson(defaultPerson) {
      if (this.lodash.isEmpty(defaultPerson)) {
        return "";
      }
      return defaultPerson.display_name;
    },
    getFormattedProperty(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }
      if (row.zip_code) {
        tempArr.push(row.zip_code);
      }
      return tempArr.join(", ");
    },
    getCustomers() {
      const _this = this;
      _this.customerLoading = true;
      return new Promise((resolve, reject) => {
        try {
          let dataCookie = getData("_btacl_tmp");
          if (
            _this.lodash.isEmpty(dataCookie) === false &&
            _this.lodash.isArray(dataCookie)
          ) {
            _this.customerLoading = false;
            resolve(dataCookie);
          } else {
            _this.$store
              .dispatch(QUERY, { url: "customer-list", data: { all: true } })
              .then(({ data }) => {
                saveData("_btacl_tmp", data);
                resolve(data);
              })
              .catch((error) => {
                reject(error);
              })
              .finally(() => {
                _this.customerLoading = false;
              });
          }
        } catch (error) {
          _this.logError(error);
          _this.customerLoading = false;
          reject(error);
        }
      });
    },
    getCustomerProperties(customer) {
      const _this = this;
      _this.propertyLoading = true;
      _this.filter_property = null;
      return new Promise((resolve, reject) => {
        try {
          let dataCookie = getData("_btacpl_" + customer + "_tmp");
          if (
            _this.lodash.isEmpty(dataCookie) === false &&
            _this.lodash.isArray(dataCookie)
          ) {
            _this.propertyLoading = false;
            resolve(dataCookie);
          } else {
            _this.$store
              .dispatch(QUERY, {
                url: "property-list/" + (customer || ""),
                data: { all: true },
              })
              .then(({ data }) => {
                saveData("_btacpl_" + customer + "_tmp", data);
                resolve(data);
              })
              .catch((error) => {
                reject(error);
              })
              .finally(() => {
                _this.propertyLoading = false;
              });
          }
        } catch (error) {
          _this.propertyLoading = false;
          reject(error);
        }
      });
    },
    getCustomerList() {
      const _this = this;
      _this
        .getCustomers()
        .then((customer) => {
          _this.customerList = customer;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getPropertyList();
        });
    },
    getPropertyList(param) {
      const _this = this;
      _this.propertyList = [];
      _this
        .getCustomerProperties(param)
        .then((status) => {
          _this.propertyList = status;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getStatusList() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, { url: "visit/status" })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      let rowData = this.lodash.map(this.rowData, function (row) {
        if (!row.header) return row;
      });

      rowData = this.lodash.without(rowData, undefined);

      return this.rowsOffset + (rowData ? rowData.length : 0);
    },
  },
  beforeDestroy() {
    /*PaginationEventBus.$off("update:pagination");
    PaginationEventBus.$off("update:per-page");*/
  },
  created() {
    const _this = this;
    PaginationEventBus.$on("update:pagination", (param) => {
      _this.currentPage = param;
      _this.getVisits();
    });

    PaginationEventBus.$on("update:per-page", (param) => {
      _this.perPage = param;
    });
  },
};
</script>
